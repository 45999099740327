// doc : https://www.algolia.com/doc/ui-libraries/autocomplete/core-concepts/templates/#using-components

if (typeof algoliasearch == 'undefined') {
    setTimeout(function () {
        const searchClient = algoliasearch(
            algolia.application_id,
            algolia.search_api_key
        );

        // indexName: 'query_suggestions',//
        const indexName = 'page';

        const predefinedItems = algolia.predefined_items;
        const predefinedItemsPlugin = {
            getSources() {
                return [
                    {
                        sourceId: 'predefinedItemsPlugin',
                        getItems({query}) {
                            //if (!query) {
                            return predefinedItems;
                            //}
                            return predefinedItems.filter((item) =>
                                item.label.toLowerCase().includes(query.toLowerCase())
                            );
                        },
                        getItemUrl({item}) {
                            return item.url;
                        },
                        templates: {
                            item({item, components}) {
                                return components.Highlight({hit: item, attribute: 'label'});
                            },
                        },
                    },
                ];
            },
        };
        
        const querySuggestionsPlugin = createQuerySuggestionsPlugin({
            searchClient,
            indexName: indexName,
            getSearchParams() {
                return {
                    hitsPerPage: 10,
                };
            },
            transformSource({source, onTapAhead}) {
                return {
                    ...source,
                    templates: {
                        ...source.templates,
                        header() {
                            return algolia.suggestion_surtitle;
                        },
                        item(params) {
                            const {item, components, html} = params;
                            // Transformation de l'item avant le rendu
                            item.query = item.post_title; // Modification de la propriété query

                            // Utilisation du template de base
                            return source.templates.item(params);
                        },
                    },
                };
            },
        });

        const autocompleteSearch = autocomplete({
            container: '#autocomplete',
            placeholder: 'Search',
            openOnFocus: true,
            detachedMediaQuery: 'none',
            classNames: {
                input: 'js-search-input'
            },
            plugins: [querySuggestionsPlugin, predefinedItemsPlugin],
        });
    }, 500);

    // Lancer la recherche après la séléction d'une valeur du querySuggestionsPlugin
    document.addEventListener('click', (event) => {
        if (event.target.closest('.aa-Item')) {
            setTimeout(function () {
                thuriaSearch.search(thuriaSearch.input.value);
            }, 50);
        }
    });
}

